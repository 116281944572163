import React from "react"
import PropTypes from "prop-types"

const Tick = ({ text }) => (
  <>
  	<img src={ '/tick.svg' } className="tick" alt={ text } /> { text }<br />
  </>
)

Tick.propTypes = {
  text: PropTypes.string,
}

Tick.defaultProps = {
  text: ``,
}

export default Tick

