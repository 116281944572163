import React, { useEffect, Component } from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
// import Hero from "../components/hero"
import Introduction from "../components/introduction"
import Spacer from "../components/spacer"
import TitleAndText from "../components/title-and-text"
import ImageAndText from "../components/image-and-text"
import BulletList from "../components/bullet-list"
import Bullet from "../components/bullet"
import Tick from "../components/tick"
import CenteredSlider from "../components/centered-slider"
import CenteredSlide from "../components/centered-slide"
import WorkGrid from "../components/work-grid"
import ImageGridItemService from "../components/image-grid-item-service"
import Testimonials from "../components/testimonials"
import ContactForm from "../components/contact-form"
import ImageGridItem from "../components/image-grid-item"
import AOS from 'aos';
import 'aos/dist/aos.css';

const WordPressSpeedPage = ({ siteTitle, data }) => {
    useEffect(() => {
      AOS.init();
    }, []);

    return (
      <Layout background={true}>
        <SEO title="WordPress Speed Improvements" />
        <div className="row">
          <div className="column small-12 text-center gm-top--4 landing-intro">
            <h1 data-aos="fade-up">Is your WordPress website <span><strong>slowing you down?</strong></span></h1>
            <p data-aos="fade-up" data-aos-delay="200">If your website is taking forever to load, we can help you get up to speed and reach your customers faster.</p>
          </div>
        </div>

        <div className="callout gm-top--4 gm-bottom--2" data-aos="fade-up" data-aos-delay="400">
          <div className="row">
            <div className="column small-12 text-center">
              <div className="callout__inner">
                <p data-aos="fade-in" data-aos-delay="800" data-aos-duration="900">Statistics show that <span>1 in 2 vistors</span> will abandon a website if it takes longer than <span>6 seconds</span> to load*</p>
              </div>
            </div>
          </div>
        </div>

        <Spacer space="2" /> 

        <div className="row shrink-width align-center">

          <div className="column small-12 large-4" data-aos="fade-up">
            <h2 className="gm-bottom--1">Stop missing out on potential leads and sales</h2>  
          </div>
          <div className="column small-12 large-8" data-aos="fade-up" data-aos-delay="200">
            <div className="row align-center">
              <div className="column small-12 large-10">
                <p className="p--large">Let us help you get your website up to speed and optimised so that customers don't abandon your website and seek out your competitors instead.</p>
                <h3 className="gm-bottom--1">How it works...</h3>
                <div className="bullet-list--large gm-bottom--2">
                  <BulletList>
                    <Tick key="1" text="A full review of your website's speed" />
                    <Tick key="2" text="A report of current website speed scores" />
                    <Tick key="3" text="A full list of suggested improvements" />
                    <Tick key="4" text="Implementation of speed improvements" />
                    <Tick key="5" text="A secondary report showing speed improvements" />
                  </BulletList>
                </div>
                <p>We’ll work with you to define your goals, identify the issues and find solutions to get your website up to speed.</p>
              </div>
            </div>
          </div>
        </div>

        <Spacer space="2" /> 

        <div className="gm-top--2 gm-bottom--2 contact-form--pullout">
          <div className="clearfix"></div>
          <div className="row align-center gm-top--4 gm-bottom--4">
            <div className="column small-12 medium-10 large-8">
              <h3>Let's Get Started</h3>
              <p>Get in touch to begin the process of speeding up your website and reaching the customers that are abandoning your slow website.</p>
              <Spacer space="2" />
              <ContactForm pageName="website-development-bournemouth" />
            </div>
          </div>
          <div className="clearfix"></div>
        </div>

        <Spacer space="1" />    

       <div className="row align-middle shrink-width gm-top--1 gm-bottom--1">
          <div className="column small-12 medium-6 medium-text-left text-center">
            <h3 className="no-margin-bottom button-title">Recent Work</h3>
            <p className="gm-bottom--1">Below is a selection of some of our recent work that we've produced here at Old Salt.</p>
          </div>
          <div className="column small-12 medium-6 text-center medium-text-right">
            <Link to="/work" className="button no-margin-bottom">View work</Link>
          </div>
        </div>
        <WorkGrid>
          <ImageGridItem width={7} image={ '../dandy.jpg' } title="Dandy Wellness" link="https://www.dandy-wellness.com/" showLink="true" />
          <ImageGridItem width={5} image={ '../zonesafe.jpg' } title="ZoneSafe" link="https://zonesafe.com/" showLink="true" />
          <ImageGridItem width={5} image={ '../project-student.jpg' } title="Project Student" link="https://mysteryawaydays.co.uk/" showLink="true" />          
          <ImageGridItem width={7} image={ '../first-wealth.jpg' } title="First Wealth" link="https://www.firstwealth.co.uk/" showLink="true" />
        </WorkGrid>               

        <Spacer space="4" />  

        <div className="row shrink-width">
          <div className="column small-12">
            <div className="row text-left">
              <div className="column small-12 large-8">
                <p><small>* All data found within this report derives from a survey commissioned by <a target="_blank" rel="noopener noreferrer" href="https://digital.com/website-speed-statistics/">Digital.com</a> and conducted online by survey platform Pollfish. In total, 1,250 American adults were surveyed. This survey was conducted on Sunday, January 16, 2022.</small></p>
              </div>
            </div>
          </div>
        </div>

      </Layout>
    )
}

export default WordPressSpeedPage

